<template>
  <header class="global-nav pbm pbn-mu mbn-mu">
    <h1 class="accessible">
      City of Philadelphia
    </h1>
    <div
      class="row columns bg-ben-franklin-blue expanded utility-nav"
      data-swiftype-index="false"
    >
      <div class="row">
        <div class="medium-24 columns float-right">
          <ul class="medium-horizontal menu show-for-medium float-right">
            <li>
              <a
                href="https://www.phila.gov/departments/mayor/"
              >Mayor's Office</a>
            </li>
            <li>
              <a
                href="https://www.phila.gov/departments/"
              >City government directory</a>
            </li>
            <li>
              <div
                id="google_translate_element"
                class=""
              >
                <span class="show-for-sr">Google Translate</span>
                <div
                  class="skiptranslate goog-te-gadget"
                  dir="ltr"
                  style=""
                >
                  <div
                    id=":0.targetLanguage"
                    class="goog-te-gadget-simple"
                    style="white-space: nowrap;"
                  >
                    <img
                      src="https://www.google.com/images/cleardot.gif"
                      class="goog-te-gadget-icon"
                      alt=""
                      style="background-image: url(&quot;https://translate.googleapis.com/translate_static/img/te_ctrl3.gif&quot;); background-position: -65px 0px;"
                    >
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      id="global-sticky-nav"
      class="row"
    >
      <div class="small-24 columns">
        <div
          class="row primary-menu"
          data-sticky-container
          data-swiftype-index="false"
        >
          <div
            class="columns sticky phn"
            data-margin-top="0"
            data-sticky
            data-sticky-on="medium"
          >
            <div class="row sticky-header-width">
              <div
                class="small-16 medium-6 columns valign small-push-4 medium-push-0"
              >
                <div class="valign-cell">
                  <a
                    href="https://www.phila.gov"
                    class="logo"
                    aria-label="City of Philadelphia"
                  >
                    <img
                      src="https://www.phila.gov/wp-content/themes/phila.gov-theme/img/city-of-philadelphia-logo.svg"
                      data-fallback="//cityofphiladelphia.github.io/patterns/images/city-of-philadelphia.png"
                      alt="City of Philadelphia"
                    >
                  </a>
                </div>
              </div>
              <div class="medium-17 columns show-for-medium desktop-nav">
                <div class="top-bar-right valign-mu">
                  <nav
                    data-swiftype-index="false"
                    class="phila-global-nav-menu valign-mu"
                    aria-label="main-nav"
                  >
                    <ul class="horizontal menu pan valign-mu">
                      <li>
                        <a
                          href="https://phlrentassist.org/"
                          class="valign-cell"
                        >PHL Rent Assist</a>
                      </li>
                      <li>
                        <a
                          href="https://www.phila.gov/departments/department-of-planning-and-development/"
                          class="valign-cell"
                        >DPD Website</a>
                      </li>
                      <li>
                        <a
                          href="https://phdcphila.org/"
                          class="valign-cell"
                        >PHDC Website</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <!--Mobile Navigation-->
            <div class="top-bar">
              <div
                class="title-bar small-5 columns"
                data-responsive-toggle="mobile-nav"
                data-swiftype-index="false"
                data-hide-for="medium"
              >
                <button
                  class="menu-icon"
                  type="button"
                  data-toggle
                >
                  <i
                    class="fas fa-bars fa-2x"
                    aria-hidden="true"
                  />
                  <span class="title-bar-title">Menu</span>
                </button>
              </div>
              <div
                id="mobile-nav"
                class="primary-menu medium-15 medium-push-2 small-24 columns equal valign-mu"
              >
                <div class="top-bar-right valign-mu show-for-small-only">
                  <nav
                    data-swiftype-index="false"
                    class="valign-mu"
                  >
                    <ul
                      id="mobile-nav-drilldown"
                      class="vertical menu pan valign-mu"
                    >
                      <!--<li>
                        <a href="https://www.phila.gov"
                          ><i class="fas fa-home fa-lg" /> Phila.gov home</a
                        >
                      </li>-->
                      <li>
                        <a
                          href="https://phlrentassist.org/"
                          class="valign-cell"
                        >PHL Rent Assist</a>
                      </li>
                      <li>
                        <a
                          href="https://www.phila.gov/departments/department-of-planning-and-development/"
                          class="valign-cell"
                        >DPD Website</a>
                      </li>
                      <li>
                        <a
                          href="https://phdcphila.org/"
                          class="valign-cell"
                        >PHDC Website</a>
                      </li>
                      
                      <!--<li>
                        <a href="https://www.phila.gov/departments/"
                          >City government directory</a
                        >
                      </li>-->
                    </ul>
                    <!--<li>
                        <div id="google_translate_element_mobile" class="no-js">
                          <span class="show-for-sr">Google Translate</span>
                        </div>
                      </li>-->
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="locale-changer">
        <select v-model="$i18n.locale">
          <option
            v-for="(lang, i) in langs"
            :key="`Lang${i}`"
            :value="lang"
          >
            {{
              lang
            }}
          </option>
        </select>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "AppHeader",
  data() {
    return {
      langs: ["English", "Spanish"],
    };
  },
};
</script>
