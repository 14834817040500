<template>
  <form
    id="applicationSubmission"
    @submit.prevent="handleSubmit"
  >
    <div class="grid-container">
      <div class="grid-y grid-margin-x">
        <h2>{{ $t("message.homeTitle") }}</h2>
        <h3>{{ $t("message.homeQuestionLabel") }}:</h3>
        <h3>{{ $t("message.homeDescriptionText") }}</h3>
        <br>
        <h3>{{ $t("message.homeDescriptionText2") }}</h3>
        <br>
        <div class="cell">
          <label
            for="input-adults-in-household"
            class="
            asterisk-label"
          >{{
            $t("message.homeAdultsQuestion")
          }}</label>
          <div
            v-if="$v.AdultsInHousehold.$error"
            class="love-park-red"
          >
            {{ $t("message.homeAdultsError") }}
          </div>
          <input
            id="input-adults-in-household"
            v-model.trim="AdultsInHousehold"
            name="input-adults-in-household"
            type="number"
            min="0"
          >
        </div>

        <div class="cell">
          <label
            for="input-children-in-household"
            class="
            asterisk-label"
          >{{
            $t("message.homeChildrenQuestion",)
          }}</label>
          <div
            v-if="$v.ChildrenInHousehold.$error"
            class="love-park-red"
          >
            {{ $t("message.homeChildrenError") }}
          </div>
          <input
            id="input-children-in-household"
            v-model.trim="ChildrenInHousehold"
            name="input-children-in-household"
            type="number"
            min="0"
          >
        </div>

        <div class="cell">
          <label
            for="input-household-income"
            class="
            asterisk-label"
          >{{
            $t("message.homeHouseholdIncome")
          }}</label>
          <div
            v-if="$v.HouseholdIncome.$error"
            class="love-park-red"
          >
            {{ $t("message.homeHouseholdIncomeError") }}
          </div>
          <input
            id="input-household-income"
            v-model.trim="HouseholdIncome"
            name="input-household-income"
            type="number"
            min="0"
          >
        </div>

        <div class="cell">
          <label
            for="input-years-of-residence"
            class="
            asterisk-label"
          >{{
            $t("message.homeYearsOfResidence")
          }}</label>
          <div
            v-if="$v.YearsOfResidence.$error"
            class="love-park-red"
          >
            {{ $t("message.homeYearsOfResidenceError") }}
          </div>
          <input
            id="input-years-of-residence"
            v-model.trim="YearsOfResidence"
            name="input-years-of-residence"
            type="number"
            min="0"
          >
        </div>

        <div class="cell">
          <fieldset>
            <legend class="h4">
              {{ $t("message.primaryResidence") }}<span class="asterisk-label" />
            </legend>
            <div
              v-if="$v.PrimaryResidence.$error"
              class="love-park-red"
            >
              {{ $t("message.primaryResidenceError") }}
            </div>
            <div>
              <input
                id="input-primary-residence-yes"
                v-model.trim="PrimaryResidence"
                type="radio"
                name="input-primary-residence"
                value="Yes"
              >
              <label for="input-primary-residence-yes">{{
                $t("message.yesLabel")
              }}</label>
            </div>
            <div>
              <input
                id="input-primary-residence-no"
                v-model.trim="PrimaryResidence"
                type="radio"
                name="input-primary-residence"
                value="No"
              >
              <label for="input-primary-residence-no">{{
                $t("message.noLabel")
              }}</label>
            </div>
          </fieldset>
        </div>
        <br>

        <div class="cell">
          <fieldset>
            <legend class="h4">
              {{ $t("message.employeeQuestion") }}<span class="asterisk-label" />
            </legend>
            <div
              v-if="$v.EmployeeStatus.$error"
              class="love-park-red"
            >
              {{ $t("message.employeeQuestionError") }}
            </div>
            <div>
              <input
                id="input-employee-question-yes"
                v-model.trim="EmployeeStatus"
                type="radio"
                name="input-employee-question"
                value="Yes"
              >
              <label for="input-employee-question-yes">{{
                $t("message.yesLabel")
              }}</label>
            </div>
            <div>
              <input
                id="input-employee-question-no"
                v-model.trim="EmployeeStatus"
                type="radio"
                name="input-employee-question"
                value="No"
              >
              <label for="input-employee-question-no">{{
                $t("message.noLabel")
              }}</label>
            </div>
          </fieldset>
        </div>
        <br>

        <div class="cell">
          <fieldset>
            <legend class="h4">
              {{ $t("message.homeDeed") }}<span class="asterisk-label" />
            </legend>
            <div
              v-if="$v.NameOnDeed.$error"
              class="love-park-red"
            >
              {{ $t("message.homeDeedError") }}
            </div>
            <div>
              <input
                id="input-name-on-deed-deed"
                v-model.trim="NameOnDeed"
                type="radio"
                name="input-name-on-deed"
                value="Deed"
              >
              <label for="input-name-on-deed-deed">{{
                $t("message.deedLabel")
              }}</label>
            </div>
            <div>
              <input
                id="input-name-on-deed-lease"
                v-model.trim="NameOnDeed"
                type="radio"
                name="input-name-on-lease"
                value="Lease"
              >
              <label for="input-name-on-deed-lease">{{
                $t("message.leaseLabel")
              }}</label>
            </div>
            <div>
              <input
                id="input-name-on-deed-neither"
                v-model.trim="NameOnDeed"
                type="radio"
                name="input-name-on-neither"
                value="Neither"
              >
              <label for="input-name-on-deed-neither">{{
                $t("message.neitherLabel")
              }}</label>
            </div>
          </fieldset>
        </div>
        <br>
        <div class="cell">
          <fieldset>
            <legend class="h4">
              {{ $t("message.homeHomeownerLabel") }}<span class="asterisk-label" />
            </legend>
            <div
              v-if="$v.HomeownerOption.$error"
              class="love-park-red"
            >
              {{ $t("message.homeHomeownerError") }}
            </div>
            <div>
              <input
                id="input-homeowner-option-homeowner"
                v-model.trim="HomeownerOption"
                type="radio"
                name="input-homeowner-option"
                value="Homeowner"
              >
              <label for="input-homeowner-option-homeowner">{{
                $t("message.homeownerLabel")
              }}</label>
            </div>
            <div>
              <input
                id="input-homeowner-option-renter"
                v-model.trim="HomeownerOption"
                type="radio"
                name="input-homeowner-option"
                value="Renter"
              >
              <label for="input-homeowner-option-renter">{{
                $t("message.renterLabel")
              }}</label>
            </div>
            <div>
              <input
                id="input-homeowner-option-neither"
                v-model.trim="HomeownerOption"
                type="radio"
                name="input-homeowner-option"
                value="Neither"
              >
              <label for="input-homeowner-option-neither">{{
                $t("message.neitherLabel")
              }}</label>
            </div>
          </fieldset>
        </div>
        <br>

        <div class="cell">
          <fieldset>
            <legend class="h4">
              {{ $t("message.homeAdditionalProperties") }}<span class="asterisk-label" />
            </legend>
            <div
              v-if="$v.SecondProperty.$error"
              class="love-park-red"
            >
              {{ $t("message.homeAdditionalPropertiesError") }}<span class="asterisk-label" />
            </div>
            <div>
              <input
                id="input-second-property-yes"
                v-model.trim="SecondProperty"
                type="radio"
                name="input-second-property"
                value="Yes"
              >
              <label for="input-second-property-yes">{{
                $t("message.yesLabel")
              }}</label>
            </div>
            <div>
              <input
                id="input-second-property-no"
                v-model.trim="SecondProperty"
                type="radio"
                name="input-second-property"
                value="No"
              >
              <label for="input-second-property-no">{{
                $t("message.noLabel")
              }}</label>
            </div>
          </fieldset>
        </div>
        <br>

        <div class="cell">
          <fieldset>
            <legend class="h4">
              {{ $t("message.homeDisability") }}<span class="asterisk-label" />
            </legend>
            <div
              v-if="$v.DisabilityOption.$error"
              class="love-park-red"
            >
              {{ $t("message.homeDisabilityError") }}
            </div>
            <div>
              <input
                id="input-disability-option-yes"
                v-model.trim="DisabilityOption"
                type="radio"
                name="input-disability-option"
                value="Yes"
              >
              <label for="input-disability-option-yes">{{
                $t("message.yesLabel")
              }}</label>
            </div>
            <div>
              <input
                id="input-disability-option-no"
                v-model.trim="DisabilityOption"
                type="radio"
                name="input-disability-option"
                value="No"
              >
              <label for="input-disability-option-no">{{
                $t("message.noLabel")
              }}</label>
            </div>
          </fieldset>
        </div>
        <br>

        <div
          v-if="PrimaryResidence === 'Yes' && HomeownerOption === 'Homeowner' "
          class="cell"
        >
          <fieldset>
            <legend class="h4">
              <b>{{ $t("message.secondaryQuestion") }}</b>
            </legend>
          </fieldset>
          <br>

          <div class="cell">
            <fieldset>
              <legend class="h4">
                {{ $t("message.roofLeak") }}<span class="asterisk-label" />
              </legend>
              <div
                v-if="$v.RoofLeak.$error"
                class="love-park-red"
              >
                {{ $t("message.roofLeakError") }} 
              </div>
              <div>
                <input
                  id="input-roof-leak-yes"
                  v-model.trim="RoofLeak"
                  type="radio"
                  name="input-roof-leak"
                  value="Yes"
                >
                <label for="input-roof-leak-yes">{{ $t("message.yesLabel") }}</label>
              </div>
              <div>
                <input
                  id="input-roof-leak-no"
                  v-model.trim="RoofLeak"
                  type="radio"
                  name="input-roof-leak"
                  value="No"
                >
                <label for="input-roof-leak-no">{{ $t("message.noLabel") }}</label>
              </div>
            </fieldset>
            <br>

            <fieldset>
              <legend class="h4">
                {{ $t("message.electricProblem") }}<span class="asterisk-label" />
              </legend>
              <div
                v-if="$v.ElectricProblem.$error"
                class="love-park-red"
              >
                {{ $t("message.electricProblemError") }} 
              </div>
              <div>
                <input
                  id="input-electric-problem-yes"
                  v-model.trim="ElectricProblem"
                  type="radio"
                  name="input-electric-problem"
                  value="Yes"
                >
                <label for="input-electric-problem-yes">{{ $t("message.yesLabel") }}</label>
              </div>
              <div>
                <input
                  id="input-electric-problem-no"
                  v-model.trim="ElectricProblem"
                  type="radio"
                  name="input-electric-problem"
                  value="No"
                >
                <label for="input-electric-problem-no">{{ $t("message.noLabel") }}</label>
              </div>
            </fieldset>
            <br>

            <fieldset>
              <legend class="h4">
                {{ $t("message.plumbingProblem") }}<span class="asterisk-label" />
              </legend>
              <div
                v-if="$v.PlumbingProblem.$error"
                class="love-park-red"
              >
                {{ $t("message.plumbingProblemError") }} 
              </div>
              <div>
                <input
                  id="input-plumbing-problem-yes"
                  v-model.trim="PlumbingProblem"
                  type="radio"
                  name="input-plumbing-problem"
                  value="Yes"
                >
                <label for="input-plumbing-problem-yes">{{ $t("message.yesLabel") }}</label>
              </div>
              <div>
                <input
                  id="input-plumbing-problem-no"
                  v-model.trim="PlumbingProblem"
                  type="radio"
                  name="input-plumbing-problem"
                  value="No"
                >
                <label for="input-plumbing-problem-no">{{ $t("message.noLabel") }}</label>
              </div>
            </fieldset>
            <br>

            <fieldset>
              <legend class="h4">
                {{ $t("message.structuralProblem") }}<span class="asterisk-label" />
              </legend>
              <div
                v-if="$v.StructuralProblem.$error"
                class="love-park-red"
              >
                {{ $t("message.structuralProblemError") }} 
              </div>
              <div>
                <input
                  id="input-structural-problem-yes"
                  v-model.trim="StructuralProblem"
                  type="radio"
                  name="input-structural-problem"
                  value="Yes"
                >
                <label for="input-structural-problem-yes">{{ $t("message.yesLabel") }}</label>
              </div>
              <div>
                <input
                  id="input-structural-problem-no"
                  v-model.trim="StructuralProblem"
                  type="radio"
                  name="input-structural-problem"
                  value="No"
                >
                <label for="input-structural-problem-no">{{ $t("message.noLabel") }}</label>
              </div>
            </fieldset>
            <br>

            <fieldset>
              <legend class="h4">
                {{ $t("message.floorStairs") }}<span class="asterisk-label" />
              </legend>
              <div
                v-if="$v.FloorStairs.$error"
                class="love-park-red"
              >
                {{ $t("message.floorStairsError") }} 
              </div>
              <div>
                <input
                  id="input-floor-stairs-yes"
                  v-model.trim="FloorStairs"
                  type="radio"
                  name="input-floor-stairs"
                  value="Yes"
                >
                <label for="input-floor-stairs-yes">{{ $t("message.yesLabel") }}</label>
              </div>
              <div>
                <input
                  id="input-floor-stairs-no"
                  v-model.trim="FloorStairs"
                  type="radio"
                  name="input-floor-stairs"
                  value="No"
                >
                <label for="input-floor-stairs-no">{{ $t("message.noLabel") }}</label>
              </div>
            </fieldset>
            <br>

            <fieldset>
              <legend class="h4">
                {{ $t("message.heaterOrHotWater") }}<span class="asterisk-label" />
              </legend>
              <div
                v-if="$v.HeaterOrHotWater.$error"
                class="love-park-red"
              >
                {{ $t("message.heaterOrHotWaterError") }} 
              </div>
              <div>
                <input
                  id="input-heater-or-hot-water-yes"
                  v-model.trim="HeaterOrHotWater"
                  type="radio"
                  name="input-heater-or-hot-water"
                  value="Yes"
                >
                <label for="input-heater-or-hot-water-yes">{{ $t("message.yesLabel") }}</label>
              </div>
              <div>
                <input
                  id="input-heater-or-hot-water-no"
                  v-model.trim="HeaterOrHotWater"
                  type="radio"
                  name="input-heater-or-hot-water"
                  value="No"
                >
                <label for="input-heater-or-hot-water-no">{{ $t("message.noLabel") }}</label>
              </div>
            </fieldset>
            <br>

            <fieldset>
              <legend class="h4">
                {{ $t("message.helpInHome") }}<span class="asterisk-label" />
              </legend>
              <div
                v-if="$v.HelpInHome.$error"
                class="love-park-red"
              >
                {{ $t("message.helpInHomeError") }} 
              </div>
              <div>
                <input
                  id="input-help-in-home-yes"
                  v-model.trim="HelpInHome"
                  type="radio"
                  name="input-help-in-home"
                  value="Yes"
                >
                <label for="input-help-in-home-yes">{{ $t("message.yesLabel") }}</label>
              </div>
              <div>
                <input
                  id="input-help-in-home-no"
                  v-model.trim="HelpInHome"
                  type="radio"
                  name="input-help-in-home"
                  value="No"
                >
                <label for="input-help-in-home-no">{{ $t("message.noLabel") }}</label>
              </div>
            </fieldset>
            <br>

            <fieldset>
              <legend class="h4">
                {{ $t("message.tangleTitle") }}<span class="asterisk-label" />
              </legend>
              <div
                v-if="$v.TangleTitle.$error"
                class="love-park-red"
              >
                {{ $t("message.tangleTitleError") }} 
              </div>
              <div>
                <input
                  id="input-tangle-title-yes"
                  v-model.trim="TangleTitle"
                  type="radio"
                  name="input-tangle-title"
                  value="Yes"
                >
                <label for="input-tangle-title-yes">{{ $t("message.yesLabel") }}</label>
              </div>
              <div>
                <input
                  id="input-tangle-title-no"
                  v-model.trim="TangleTitle"
                  type="radio"
                  name="input-tangle-title"
                  value="No"
                >
                <label for="input-tangle-title-no">{{ $t("message.noLabel") }}</label>
              </div>
            </fieldset>
            <br>

            <fieldset>
              <legend class="h4">
                {{ $t("message.solarPower") }}<span class="asterisk-label" />
              </legend>
              <div
                v-if="$v.SolarPower.$error"
                class="love-park-red"
              >
                {{ $t("message.solarPowerError") }} 
              </div>
              <div>
                <input
                  id="input-solar-power-yes"
                  v-model.trim="SolarPower"
                  type="radio"
                  name="input-solar-power"
                  value="Yes"
                >
                <label for="input-solar-power-yes">{{ $t("message.yesLabel") }}</label>
              </div>
              <div>
                <input
                  id="input-solar-power-no"
                  v-model.trim="SolarPower"
                  type="radio"
                  name="input-solar-power"
                  value="No"
                >
                <label for="input-solar-power-no">{{ $t("message.noLabel") }}</label>
              </div>
            </fieldset>
            <br>

            <fieldset>
              <legend class="h4">
                {{ $t("message.billProblem") }}<span class="asterisk-label" />
              </legend>
              <div
                v-if="$v.BillProblem.$error"
                class="love-park-red"
              >
                {{ $t("message.billProblemError") }} 
              </div>
              <div>
                <input
                  id="input-bill-problem-yes"
                  v-model.trim="BillProblem"
                  type="radio"
                  name="input-bill-problem"
                  value="Yes"
                >
                <label for="input-bill-problem-yes">{{ $t("message.yesLabel") }}</label>
              </div>
              <div>
                <input
                  id="input-bill-problem-no"
                  v-model.trim="BillProblem"
                  type="radio"
                  name="input-bill-problem"
                  value="No"
                >
                <label for="input-bill-problem-no">{{ $t("message.noLabel") }}</label>
              </div>
            </fieldset>
            <br>
          </div>
        </div>

        <div class="cell">
          <fieldset>
            <legend class="h4">
              {{ $t("message.homeReferralQuestion") }}<span class="asterisk-label" />
            </legend>
            <div
              v-if="$v.referralType.$error"
              class="love-park-red"
            >
              {{ $t("message.homeReferralQuestionError") }}
            </div>
            <div>
              <input
                id="input-referralType-option-WoM"
                v-model.trim="referralType"
                type="radio"
                name="input-referralType-option"
                value="Word of Mouth"
              >
              <label for="input-referralType-option-WoM">{{
                $t("message.wordOfMouthLabel")
              }}</label>
            </div>
            <div>
              <input
                id="input-referralType-option-sm"
                v-model.trim="referralType"
                type="radio"
                name="input-referralType-option"
                value="Social Media"
              >
              <label for="input-referralType-option-sm">{{
                $t("message.socialMediaLabel")
              }}</label>
            </div>
            <div>
              <input
                id="input-referralType-option-comm"
                v-model.trim="referralType"
                type="radio"
                name="input-referralType-option"
                value="Community Partner"
              >
              <label for="input-referralType-option-comm">{{
                $t("message.communityPartnerLabel")
              }}</label>
            </div>
            <div>
              <input
                id="input-referralType-option-cityC"
                v-model.trim="referralType"
                type="radio"
                name="input-referralType-option"
                value="City Council Office"
              >
              <label for="input-referralType-option-cityC">{{
                $t("message.cityCouncilLabel")
              }}</label>
            </div>
            <div>
              <input
                id="input-referralType-option-news"
                v-model.trim="referralType"
                type="radio"
                name="input-referralType-option"
                value="Newspaper"
              >
              <label for="input-referralType-option-news">{{
                $t("message.newspaperLabel")
              }}</label>
            </div>
            <div>
              <input
                id="input-referralType-option-email"
                v-model.trim="referralType"
                type="radio"
                name="input-referralType-option"
                value="Email"
              >
              <label for="input-referralType-option-email">{{
                $t("message.emailOptionLabel")
              }}</label>
            </div>
            <div>
              <input
                id="input-referralType-option-other"
                v-model.trim="referralType"
                type="radio"
                name="input-referralType-option"
                value="Other"
              >
              <label for="input-referralType-option-other">{{
                $t("message.otherLabel")
              }}</label>
            </div>
          </fieldset>
        </div>

        <br>
        <div>
          <i>{{ $t("message.homeSubmissionLabel") }}</i>
        </div>
        <hr>
        <div class="grid-x grid-padding-x align-right">
          <div class="cell medium-4">
            <button
              class="button"
            >
              {{ $t("message.homeSubmit") }}
            </button>
          </div>
        </div>
        <p
          v-if="submitStatus === 'OK'"
          class="typo__p"
        >
          {{ $t("message.homeThankYou") }}
        </p>
        <p
          v-if="submitStatus === 'ERROR'"
          class="typo__p"
        >
          {{ $t("message.homeApplicationError") }}
        </p>
        <p
          v-if="submitStatus === 'PENDING'"
          class="typo__p"
        >
          {{ $t("message.homeSubmittingLabel") }}
        </p>
      </div>
    </div>
  </form>
</template>

<script>

import { required } from 'vuelidate/lib/validators';
//import { withParams } from 'vuelidate/lib';
const money = value => /^\$?\d+(,\d{3})*\.?[0-9]?[0-9]?$/.test(value);

// const requiredIfHomeowner = (value) =>{
//   return withParams(
//     { type: 'requiredIfHomeowner' },
//     function (value) {
//       if (this.PrimaryResidence === 'Yes' && this.HomeownerOption === 'Homeowner') {
//         return required(value);
//       }
//       return true;
//     },
//   )(value);
// };

export default{
  name: "Home",
  data() {
    return {
      submitStatus: null,
      AdultsInHousehold: null,
      ChildrenInHousehold: null,
      HouseholdIncome: null,
      YearsOfResidence: null,
      DisabilityOption: null,
      HomeownerOption: null,
      SecondProperty: null,
      PrimaryResidence: null,
      NameOnDeed: null,
      referralType: null,
      AdditionalHouseholds: null /*edited*/,
      EmployeeStatus: null,
      RoofLeak: null,
      ElectricProblem: null,
      PlumbingProblem: null,
      StructuralProblem: null,
      FloorStairs: null,
      HeaterOrHotWater: null,
      HelpInHome: null,
      TangleTitle: null,
      SolarPower: null,
      BillProblem: null,
    };
  },
  validations: {
    AdultsInHousehold: { required } /*my variables start here*/,
    ChildrenInHousehold: { required },
    HouseholdIncome: { required, money },
    YearsOfResidence: { required },
    DisabilityOption: { required },
    HomeownerOption: { required },
    SecondProperty: { required },
    PrimaryResidence: { required },
    NameOnDeed: { required },
    referralType: { required },
    EmployeeStatus: { required },
    RoofLeak: {
      required() {
        if (this.PrimaryResidence === "Yes" && this.HomeownerOption === "Homeowner") {
          return required(this.RoofLeak);
        }
        return true;
      },
    },
    ElectricProblem: {
      required() {
        if (this.PrimaryResidence === "Yes" && this.HomeownerOption === "Homeowner") {
          return required(this.ElectricProblem);
        }
        return true;
      },
    },
    PlumbingProblem: {
      required() {
        if (this.PrimaryResidence === "Yes" && this.HomeownerOption === "Homeowner") {
          return required(this.PlumbingProblem);
        }
        return true;
      },
    },
    StructuralProblem: {
      required() {
        if (this.PrimaryResidence === "Yes" && this.HomeownerOption === "Homeowner") {
          return required(this.StructuralProblem);
        }
        return true;
      },
    },
    FloorStairs: {
      required() {
        if (this.PrimaryResidence === "Yes" && this.HomeownerOption === "Homeowner") {
          return required(this.FloorStairs);
        }
        return true;
      },
    },
    HeaterOrHotWater: {
      required() {
        if (this.PrimaryResidence === "Yes" && this.HomeownerOption === "Homeowner") {
          return required(this.HeaterOrHotWater);
        }
        return true;
      },
    },
    HelpInHome: {
      required() {
        if (this.PrimaryResidence === "Yes" && this.HomeownerOption === "Homeowner") {
          return required(this.HelpInHome);
        }
        return true;
      },
    },
    TangleTitle: {
      required() {
        if (this.PrimaryResidence === "Yes" && this.HomeownerOption === "Homeowner") {
          return required(this.TangleTitle);
        }
        return true;
      },
    },
    SolarPower: {
      required() {
        if (this.PrimaryResidence === "Yes" && this.HomeownerOption === "Homeowner") {
          return required(this.SolarPower);
        }
        return true;
      },
    },
    BillProblem: {
      required() {
        if (this.PrimaryResidence === "Yes" && this.HomeownerOption === "Homeowner") {
          return required(this.BillProblem);
        }
        return true;
      },
    },
  },
  //computed: {

  //},
  methods: {
    handleSubmit() {
      var vData = this;
      this.$v.$touch();
      if (this.$v.$invalid && this.$i18n.locale == "English") {
        this.submitStatus = "ERROR";
        this.$alert(
          "All questions are required. Please ensure all questions have been answered before sumbitting.",
        );
      } else if (this.$v.$invalid && this.$i18n.locale == "Spanish") {
        this.submitStatus = "ERROR";
        this.$alert(
          "Debe responder a todas las preguntas. Favor de asegurarse de que contestó todas las preguntas antes de enviar.",
        );
      } else {
        //else for error handing
        this.submitStatus = "OK";
        vData.$router.push({
          name: "Results",
          params: {
            adultsPath: vData.AdultsInHousehold,
            childPath: vData.ChildrenInHousehold,
            yearsPath: vData.YearsOfResidence,
            disabilityPath: vData.DisabilityOption,
            primaryResidencePath: vData.PrimaryResidence,
            homeownerPath: vData.HomeownerOption,
            incomePath: vData.HouseholdIncome,
            spropertyPath: vData.SecondProperty,
            deedPath: vData.NameOnDeed,
            referralPath: vData.referralType,
            employeePath: vData.EmployeeStatus,
            roofLeakPath: vData.RoofLeak,
            electricProblemPath: vData.ElectricProblem,
            plumbingProblemPath: vData.PlumbingProblem,
            structuralProblemPath: vData.StructuralProblem,
            floorStairsPath: vData.FloorStairs,
            heaterOrHotWaterPath: vData.HeaterOrHotWater,
            helpInHomePath: vData.HelpInHome,
            tangleTitlePath: vData.TangleTitle,
            solarPowerPath: vData.SolarPower,
            billProblemPath: vData.BillProblem,

          },
        });
      }
    },
  },
};
</script>

<style scoped>
.table-container {
  min-height: 100px;
  max-height: 400px;
  overflow-y: auto;
  width: 100%;
  margin-bottom: 1.14286rem;
}
.asterisk-label::after {
  content: " *";
  color: red;
  font-size: 25px;
}
table {
  margin: 0 !important;
}
.table-container th {
  position: sticky !important;
  top: 0 !important;
}
.file-upload-button {
  margin-bottom: 1.14286rem;
}
</style>
