import App from "./App.vue";
import AppHeader from "@/components/shared/AppHeader.vue";
import Home from "./components/Home";
import Results from "./components/Results";
import Vue from "vue";
import VueSimpleAlert from "vue-simple-alert";
import VueI18n from "vue-i18n";
import VueGtag from "vue-gtag";
import VueRouter from "vue-router";
import Vuelidate from "vuelidate";

import { getPropertyValueOrDefault } from "@/utilities/functions";
import messages from "@/i18nMessages";

const isProduction = process.env.NODE_ENV === "production";
Vue.config.productionTip = true;
Vue.config.devtools = !isProduction;

Vue.use(VueRouter);
Vue.use(Vuelidate);
Vue.use(VueSimpleAlert);
Vue.use(VueI18n);

const router = new VueRouter({
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: {
        analytics: {
          path: "/Home/",
        },
      },
    },
    {
      path: "/Results",
      name: "Results",
      component: Results,
      props: true,
      meta: {
        analytics: {
          path: "/Results/",
        },
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    //sets Vue pages to start at top of the page for new routes https://router.vuejs.org/guide/advanced/scroll-behavior.html#async-scrolling
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

Vue.use(
  VueGtag,
  {
    config: { id: "G-3K864LY9PM" },
    pageTrackerTemplate(to) {
      const pagePath =
        getPropertyValueOrDefault(["meta", "analytics", "path"], to) || to.path;
      return {
        page_title: to.name,
        page_path: pagePath,
        page_location: window.location.href,
      };
    },
  },
  router,
);

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: "English", // set locale
  messages, // set locale messages
});

const app = new Vue({
  el: '#app',
  router,
  i18n,
  mounted: function() {
    // fix for IE 11
    if (!!window.MSInputMethodContext && !!document.documentMode) {
      window.addEventListener("hashchange", this.hashChangeHandler);
    }
  },
  destroyed: function() {
    // fix for IE 11
    if (!!window.MSInputMethodContext && !!document.documentMode) {
      window.removeEventListener("hashchange", this.hashChangeHandler);
    }
  },
  methods: {
    hashChangeHandler: function() {
      var target = window.location.hash;
      this.$router.push(target.substring(1, target.length));
    },
  },
  render(h) {
    return h(App, {});
  },
});

const header = new Vue({
  el: "#header-container",
  i18n,
  render(h) {
    return h(AppHeader, {});
  },
});

export { app, header };
